import request from '@/utils/request'

// 基础的单文件上传
// export function baseUploadFile(file, fileClassify) {
//   return request({
//     url: '/system/file/uploadFile',
//     method: 'post',
//     params: {file: file, fileClassify: fileClassify}
//   })
// }

// 删除文件
export function delFile(id) {
  return request({
    url: '/system/file/delFile',
    method: 'post',
    params: {id: id}
  })
}

// 下载/预览文件,返回二进制文件流
export function downloadFile(id) {
  return request({
    url: '/system/file/preview',
    method: 'get',
    params: {id:id},
    responseType: 'blob'
  })
}


