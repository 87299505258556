<template>
  <div v-if="editFeeFlag" class="editFee">
    <div class="header">
      <div class="title">编辑稿酬</div>
      <img @click="close" style="width: 15px;height:15px;cursor: pointer;" src="../../assets/images/close.png" />
    </div>
    <div class="content">
      <div style="display: flex;align-items: center;margin-bottom: 20px;">
        <div style="width: 100px">系统计算：</div>
        <div>{{sysTotalFee}}</div>
      </div>
      <div>
        <!-- <div style="width: 100px">自定义：</div> -->
        <el-form :model="ruleForm" :rules="rules" label-position="left" label-width="100px" ref="ruleForm" class="demo-ruleForm">
          <el-form-item label="自定义：" prop="customFee">
            <el-input  v-model="ruleForm.customFee" placeholder="请输入自定义稿酬"></el-input>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <div class="footer">
      <div class="comfirn" @click="comfirn">确认</div>
      <div class="comfirn" style="margin-left:20px" @click="close">关闭</div>
    </div>
  </div>
</template>
<script>
import {feeInfo, editFee} from '../../api/userHome.js'
export default {
  props: ['editFeeFlag', 'editFeeId'],
  data() {
    return {
      sysTotalFee: '',
      //customFee: ''
      ruleForm: {
        customFee: ''
      },
      rules: {
        customFee: [
          { pattern: /^(\d|[1-9]\d{1,3})(\.\d{0,2})?$/,message: '请输入四位数以内的数字且小数点不超过两位',trigger:  ['blur', 'change']}
        ],
      }
    }
  },
  watch: {
    editFeeFlag(newVal) {
      if(newVal) {
        this.getFeeInfo()
      }
    }
  },
  created() {

  },
  methods: {
    async getFeeInfo() {
      let res = await feeInfo(this.editFeeId)
      if(res.code == 200) {
        this.sysTotalFee = res.data.sysTotalFee
        this.ruleForm.customFee = res.data.customFee
      }
    },
    comfirn() {
      this.$refs.ruleForm.validate( async (valid) => {
        if (valid) {
          const params = {
            fee: this.ruleForm.customFee,
            id: parseInt(this.editFeeId)
          }
          let res = await editFee(params)
          if(res.code == 200) {
            this.$message({
              message: '完成',
              type: 'success'
            });
            this.ruleForm.customFee = ''
            this.$emit('editFeeConfirm')
          }
        } else {
          return false
        }
      })
    },
    close() {
      this.ruleForm.customFee = ''
      this.$emit('closeEditFeeFlag')
    }
  },
}
</script>
<style lang="scss" scoped>
.editFee {
  //height: 320px;
  width: 500px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 8px 8px 0px 0px;
  border-radius: 8px 8px 0px 0px;
  background: #FFF;
  display: flex;
  flex-direction: column;
  z-index: 1000;
  .footer {
    display: flex;
    padding: 20px 20px 10px 20px;
    justify-content: center;
    align-items: center;
    flex: 0 0 60px;
    box-sizing: border-box;
    .comfirn {
      //width: 68px;
      cursor: pointer;
      height: 30px;
      border-radius: 4px;
      border: 1px solid #B9B9B9;
      background: #FFF;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 8px 20px;
      box-sizing: border-box;
      line-height: 14px;
      font-size: 14px;
      color: #747474;
    }
  }
  .content {
    padding: 10px 20px;
    flex: 1;
    //display: flex;
  }
  .header {
    display: flex;
    padding: 20px 20px 10px 20px;
    justify-content: space-between;
    align-items: center;
    flex: 0 0 60px;
    box-sizing: border-box;
    .title {
      color: #262626;
      font-size: 20px;
      font-weight: bold;
      line-height: 30px;
    }
  }
}
</style>
