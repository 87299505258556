<template>
  <div v-if="scheduleDateFlag" class="scheduleDate">
		<div class="header">
			<div class="title">排期</div>
			<img @click="close" style="width: 15px;height:15px;cursor: pointer;" src="../../assets/images/close.png" />
		</div>
		<div class="content">
			<div>日期：</div>
			<el-date-picker
				v-model="scheduleDate"
				type="date"
				size="small"
				value-format="yyyy-MM-dd"
				placeholder="选择日期">
			</el-date-picker>
		</div>
		<div class="footer">
			<div class="comfirn" @click="comfirn">确认</div>
		</div>
  </div>
</template>
<script>
import {preDate} from '../../api/userHome.js'
export default {
	props: ['scheduleDateFlag', 'id', 'prePublishDate'],
  data() {
    return {
			scheduleDate: ''
    }
  },
	watch: {
		scheduleDateFlag(newVal) {
			if(newVal) {
				this.scheduleDate = this.prePublishDate
			}
		}
	},
  created() {

  },
  methods: {
		async comfirn() {
			if(!this.scheduleDate) {
				this.$message({
          message: '请选择时间',
          type: 'error'
        });
				return
			}
			const params = {
				id: parseInt(this.id),
				date: this.scheduleDate
			}
			let res = await preDate(params)
			if(res.code == 200) {
				this.$message({
          message: '完成',
          type: 'success'
        });
				this.scheduleDate = ''
				this.$emit('scheduleDateconfirm')
			}
		},
		close() {
			this.scheduleDate = ''
			this.$emit('closeScheduleDate')
		}
  },
}
</script>
<style lang="scss" scoped>
.scheduleDate {
	//height: 320px;
	width: 500px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	border-radius: 8px 8px 0px 0px;
	border-radius: 8px 8px 0px 0px;
	background: #FFF;
	display: flex;
	flex-direction: column;
	z-index: 1000;
	.footer {
		display: flex;
		padding: 20px 20px 10px 20px;
		justify-content: center;
		align-items: center;
		flex: 0 0 60px;
		box-sizing: border-box;
		.comfirn {
			//width: 68px;
			cursor: pointer;
			height: 30px;
			border-radius: 4px;
			border: 1px solid #B9B9B9;
			background: #FFF;
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 8px 20px;
			box-sizing: border-box;
			line-height: 14px;
			font-size: 14px;
			color: #747474;
		}
	}
	.content {
		padding: 10px 20px;
		flex: 1;
		display: flex;
	}
	.header {
		display: flex;
		padding: 20px 20px 10px 20px;
		justify-content: space-between;
		align-items: center;
		flex: 0 0 60px;
		box-sizing: border-box;
		.title {
			color: #262626;
			font-size: 20px;
			font-weight: bold;
			line-height: 30px;
		}
	}
}
</style>
