<template>
  <div v-if="finishLayoutFlag" class="finishLayout">
		<div class="header">
			<div class="title">预览链接</div>
			<img @click="close" style="width: 15px;height:15px;cursor: pointer;" src="../../assets/images/close.png" />
		</div>
		<div class="content">
			<el-upload
				class="upload-demo"
				:headers="headers"
				:before-upload="beforeUpload"
				:action="baseURL+'/system/file/uploadFile'"
				:on-remove="removeFile"
				:data="uploadParams"
				:limit="1"
				:on-success="(res, file, fileList)=>{uploadSuccess(res, file, fileList)}"
				:file-list="fileList">
				<el-button size="small" type="primary">上传预览文件</el-button>
			</el-upload>
		</div>
		<div class="footer">
			<div class="comfirn" @click="comfirn">确认</div>
		</div>
  </div>
</template>
<script>
import {finishLayout, preLink } from '../../api/userHome.js'
import { getToken } from '@/utils/auth'
export default {
	props: ['finishLayoutFlag', 'id', 'linkType', 'previewLink'],
  data() {
    return {
			link: '',
			headers: {
        Authorization: getToken()
      },
			baseURL: process.env.VUE_APP_BASE_API,
			// baseURL:'http://172.16.0.109:8070',
			//上传文件额外参数
      uploadParams: {
        fileClassify: 12,
        tempFlag: 1
      },
			fileList: [],
			linkId: ''
    }
  },
  created() {

  },
	watch: {
		finishLayoutFlag(newVal) {
			if(newVal) {
				this.link = this.previewLink
			}
		}
	},
  methods: {
		uploadSuccess(response,file,fileList) {
			if(response.code == 200) {
				// this.contributeFileName = fileList[0].name
				this.fileList = fileList
				this.linkId = fileList[0].response.data.id
			} else {
				this.fileList = []
				this.$message.error(response.msg || '上传失败')
			}
    },
		removeFile() {
			this.fileList = []
			this.linkId = ''
		},
		// 文件上传前校验
    beforeUpload(file) {
      let fileName = file.name
      let lastIndex = fileName.lastIndexOf('.')
      let type = fileName.substring(lastIndex+1) // doc,docx,xls,xlsx,ppt,pptx,txt,pdf
      // if (this.form.type===2) {
      if (type !== 'pdf') {
        this.$message.error('预览文件支持PDF文件格式')
        return false;
      }
      // }
    },
		async comfirn() {
			if(this.fileList.length==0) {
				this.$message({
          message: '请上传预览文件',
          type: 'error'
        });
				return
			}
			const params = {
				id: parseInt(this.id),
				link: '',
				linkId: parseInt(this.linkId)
			}
			let res = {}
			if(this.linkType == '1') {
				res = await finishLayout(params)
			} else if(this.linkType == '2') {
				res = await preLink(params)
			}
			if(res.code == 200) {
				this.$message({
          message: '完成',
          type: 'success'
        });
				this.fileList = []
				this.linkId = ''
				this.$emit('finishLayoutconfirm')
			}
		},
		close() {
			this.fileList = []
			this.linkId = ''
			this.$emit('closeFinishLayout')
		}
  },
}
</script>
<style lang="scss" scoped>
::v-deep .el-textarea__inner {
	height: 100%
}
.finishLayout {
	height: 320px;
	width: 500px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	border-radius: 8px 8px 0px 0px;
	border-radius: 8px 8px 0px 0px;
	background: #FFF;
	display: flex;
	flex-direction: column;
	z-index: 1000;
	.footer {
		display: flex;
		padding: 20px 20px 10px 20px;
		justify-content: center;
		align-items: center;
		flex: 0 0 60px;
		box-sizing: border-box;
		.comfirn {
			//width: 68px;
			cursor: pointer;
			height: 30px;
			border-radius: 4px;
			border: 1px solid #B9B9B9;
			background: #FFF;
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 8px 20px;
			box-sizing: border-box;
			line-height: 14px;
			font-size: 14px;
			color: #747474;
		}
	}
	.content {
		padding: 10px 20px;
		flex: 1;
		display: flex;
	}
	.header {
		display: flex;
		padding: 20px 20px 10px 20px;
		justify-content: space-between;
		align-items: center;
		flex: 0 0 60px;
		box-sizing: border-box;
		.title {
			color: #262626;
			font-size: 20px;
			font-weight: bold;
			line-height: 30px;
		}
	}
}
</style>
