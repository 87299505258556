var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.publishFlag
    ? _c("div", { staticClass: "publish" }, [
        _c("div", { staticClass: "header" }, [
          _c("div", { staticClass: "title" }, [_vm._v("确认发布")]),
          _c("img", {
            staticStyle: { width: "15px", height: "15px", cursor: "pointer" },
            attrs: { src: require("../../assets/images/close.png") },
            on: { click: _vm.close },
          }),
        ]),
        _c(
          "div",
          { staticClass: "content" },
          [
            _c(
              "div",
              { staticStyle: { "margin-bottom": "10px" } },
              [
                _vm._v("日期："),
                _c("el-date-picker", {
                  attrs: {
                    type: "date",
                    size: "small",
                    "value-format": "yyyy-MM-dd",
                    placeholder: "选择日期",
                  },
                  model: {
                    value: _vm.date,
                    callback: function ($$v) {
                      _vm.date = $$v
                    },
                    expression: "date",
                  },
                }),
              ],
              1
            ),
            _c("el-input", {
              staticStyle: { flex: "1" },
              attrs: { type: "textarea", placeholder: "在此处粘贴文章链接" },
              model: {
                value: _vm.publishLink,
                callback: function ($$v) {
                  _vm.publishLink = $$v
                },
                expression: "publishLink",
              },
            }),
            _c(
              "el-checkbox",
              {
                staticStyle: { margin: "10px 0" },
                model: {
                  value: _vm.originalRewardFlag,
                  callback: function ($$v) {
                    _vm.originalRewardFlag = $$v
                  },
                  expression: "originalRewardFlag",
                },
              },
              [_vm._v("原创奖励")]
            ),
            _c(
              "el-row",
              { staticStyle: { "margin-bottom": "10px" } },
              [
                _c("el-col", { attrs: { span: 5 } }, [
                  _c("div", { staticStyle: { "line-height": "36px" } }, [
                    _vm._v("封面地址："),
                  ]),
                ]),
                _c(
                  "el-col",
                  { attrs: { span: 13 } },
                  [
                    _c("el-input", {
                      attrs: { clearable: "" },
                      on: { clear: _vm.clearClick },
                      model: {
                        value: _vm.form.file,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "file", $$v)
                        },
                        expression: "form.file",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-col",
                  {
                    staticStyle: { "text-align": "right" },
                    attrs: { span: 6 },
                  },
                  [
                    _c(
                      "el-upload",
                      {
                        ref: "upload",
                        staticClass: "upload-demo",
                        attrs: {
                          action: _vm.baseURL + "/system/file/uploadFile",
                          data: _vm.uploadParams,
                          "on-preview": _vm.handleFilePreview,
                          "before-upload": _vm.beforeUpload,
                          "on-success": function (res, file, fileList) {
                            _vm.uploadSuccess(res, file, fileList)
                          },
                          "on-remove": _vm.handleRemove,
                          "before-remove": _vm.beforeRemove,
                          "show-file-list": false,
                          multiple: "",
                          limit: 1,
                          "on-exceed": _vm.handleExceed,
                          "file-list": _vm.fileList,
                        },
                      },
                      [
                        _vm.fileList.length === 0
                          ? _c(
                              "el-button",
                              {
                                staticStyle: {
                                  border: "1px solid #2D5AFA",
                                  color: "#2D5AFA",
                                },
                                attrs: {
                                  slot: "trigger",
                                  size: "small",
                                  loading: _vm.uploadLoading,
                                },
                                slot: "trigger",
                              },
                              [_vm._v("点击上传")]
                            )
                          : _c(
                              "el-button",
                              {
                                staticStyle: {
                                  border: "1px solid #2D5AFA",
                                  color: "#2D5AFA",
                                },
                                attrs: {
                                  slot: "tip",
                                  size: "small",
                                  loading: _vm.uploadLoading,
                                  disabled: true,
                                },
                                slot: "tip",
                              },
                              [_vm._v("点击上传")]
                            ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "el-row",
              [
                _vm.previewPhotoUrl
                  ? _c("el-col", { attrs: { span: 24 } }, [
                      _c("img", {
                        staticStyle: { width: "100%" },
                        attrs: { src: _vm.previewPhotoUrl, alt: "photo" },
                      }),
                    ])
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
        _c("div", { staticClass: "footer" }, [
          _c("div", { staticClass: "comfirn", on: { click: _vm.comfirn } }, [
            _vm._v("确认"),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }