import { render, staticRenderFns } from "./handleManuscriptCompenent.vue?vue&type=template&id=344fb598&scoped=true&"
import script from "./handleManuscriptCompenent.vue?vue&type=script&lang=js&"
export * from "./handleManuscriptCompenent.vue?vue&type=script&lang=js&"
import style0 from "./handleManuscriptCompenent.vue?vue&type=style&index=0&id=344fb598&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "344fb598",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/macos/workspaces/winning2024/pdf-web/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('344fb598')) {
      api.createRecord('344fb598', component.options)
    } else {
      api.reload('344fb598', component.options)
    }
    module.hot.accept("./handleManuscriptCompenent.vue?vue&type=template&id=344fb598&scoped=true&", function () {
      api.rerender('344fb598', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/modules/thys/view/userHome/component/handleManuscriptCompenent.vue"
export default component.exports