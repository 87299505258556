var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.rejectOrEditManuscriptFlag
    ? _c("div", { staticClass: "rejectOrEditManuscript" }, [
        _c("div", { staticClass: "header" }, [
          _c("div", { staticClass: "title" }, [
            _vm._v(_vm._s(_vm.contributeStatus == "3" ? "修回" : "拒稿")),
          ]),
          _c("img", {
            staticStyle: { width: "15px", height: "15px", cursor: "pointer" },
            attrs: { src: require("../../assets/images/close.png") },
            on: { click: _vm.close },
          }),
        ]),
        _c(
          "div",
          { staticClass: "content" },
          [
            _vm.contributeStatus == "3"
              ? _c(
                  "el-upload",
                  {
                    staticClass: "upload-demo",
                    attrs: {
                      headers: _vm.headers,
                      "before-upload": _vm.beforeUpload,
                      action: _vm.baseURL + "/system/file/uploadFile",
                      "on-remove": _vm.removeFile,
                      data: _vm.uploadParams,
                      limit: 1,
                      "on-success": function (res, file, fileList) {
                        _vm.uploadSuccess(res, file, fileList)
                      },
                      "file-list": _vm.fileList,
                    },
                  },
                  [
                    _c(
                      "el-button",
                      { attrs: { size: "small", type: "primary" } },
                      [_vm._v("上传修回文件")]
                    ),
                  ],
                  1
                )
              : _c("el-input", {
                  staticStyle: { flex: "1" },
                  attrs: { type: "textarea", placeholder: "请输入内容" },
                  model: {
                    value: _vm.reason,
                    callback: function ($$v) {
                      _vm.reason = $$v
                    },
                    expression: "reason",
                  },
                }),
          ],
          1
        ),
        _c("div", { staticClass: "footer" }, [
          _c("div", { staticClass: "comfirn", on: { click: _vm.comfirn } }, [
            _vm._v("确认"),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }