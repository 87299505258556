var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.receiveManuscriptFlag
    ? _c("div", { staticClass: "receiveManuscript" }, [
        _c("div", { staticClass: "header" }, [
          _c("div", { staticClass: "title" }, [_vm._v("接收")]),
          _c("img", {
            staticStyle: { width: "15px", height: "15px", cursor: "pointer" },
            attrs: { src: require("../../assets/images/close.png") },
            on: { click: _vm.close },
          }),
        ]),
        _c(
          "div",
          { staticClass: "content" },
          [
            _c(
              "el-upload",
              {
                staticClass: "upload-demo",
                attrs: {
                  headers: _vm.headers,
                  "before-upload": _vm.beforeUpload,
                  action: _vm.baseURL + "/system/file/uploadFile",
                  data: _vm.uploadParams,
                  limit: 1,
                  "on-success": function (res, file, fileList) {
                    _vm.uploadSuccess(res, file, fileList)
                  },
                  "file-list": _vm.fileList,
                },
              },
              [
                _c("el-button", { attrs: { size: "small", type: "primary" } }, [
                  _vm._v("上传修改文档"),
                ]),
              ],
              1
            ),
          ],
          1
        ),
        _c("div", { staticClass: "footer" }, [
          _c("div", { staticClass: "comfirn", on: { click: _vm.comfirn } }, [
            _vm._v("确认"),
          ]),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }