var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "handleManuscriptCompenent" },
    [
      _c(
        "el-menu",
        {
          staticClass: "el-menu-demo",
          attrs: { "default-active": _vm.activeIndex, mode: "horizontal" },
          on: { select: _vm.handleSelect },
        },
        [
          _c("el-menu-item", { attrs: { index: "1" } }, [_vm._v("未发布")]),
          _c("el-menu-item", { attrs: { index: "9" } }, [_vm._v("已发布")]),
        ],
        1
      ),
      _c(
        "div",
        {
          staticStyle: {
            display: "flex",
            "justify-content": "space-between",
            margin: "16px 0 28px",
          },
        },
        [
          _c(
            "div",
            [
              _c("el-input", {
                staticStyle: { width: "260px" },
                attrs: { placeholder: "请输入内容查询" },
                on: { input: _vm.inputValue },
                model: {
                  value: _vm.searchValue,
                  callback: function ($$v) {
                    _vm.searchValue = $$v
                  },
                  expression: "searchValue",
                },
              }),
              _c(
                "el-select",
                {
                  staticStyle: { margin: "0 10px" },
                  attrs: { clearable: "", placeholder: "请选择投稿公众号" },
                  on: { change: _vm.inputValue },
                  model: {
                    value: _vm.configId,
                    callback: function ($$v) {
                      _vm.configId = $$v
                    },
                    expression: "configId",
                  },
                },
                _vm._l(_vm.appList, function (item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.appName, value: item.id },
                  })
                }),
                1
              ),
              _c(
                "el-select",
                {
                  attrs: { clearable: "", placeholder: "请选择稿件状态" },
                  on: { change: _vm.inputValue },
                  model: {
                    value: _vm.contributeStatus,
                    callback: function ($$v) {
                      _vm.contributeStatus = $$v
                    },
                    expression: "contributeStatus",
                  },
                },
                _vm._l(_vm.contributeStatusList, function (item) {
                  return _c("el-option", {
                    key: item.code,
                    attrs: { label: item.name, value: item.code },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function ($event) {
                  _vm.newScriptFlag = true
                },
              },
            },
            [_vm._v("投稿")]
          ),
        ],
        1
      ),
      _c(
        "div",
        { ref: "tableList", staticClass: "tableList" },
        [
          _c(
            "el-table",
            {
              directives: [
                {
                  name: "loading",
                  rawName: "v-loading",
                  value: _vm.loading,
                  expression: "loading",
                },
              ],
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.tableData,
                border: "",
                fit: "",
                stripe: "",
                height: _vm.tableHeight,
              },
            },
            [
              _c("el-table-column", {
                attrs: { prop: "title", label: "标题" },
              }),
              _c("el-table-column", {
                attrs: { prop: "userName", label: "用户名" },
              }),
              _c("el-table-column", {
                attrs: { prop: "realName", label: "真实姓名" },
              }),
              _c("el-table-column", {
                attrs: { prop: "contributeDate", label: "投稿日期" },
              }),
              _c("el-table-column", {
                attrs: { prop: "publishDate", label: "发布日期" },
              }),
              _c("el-table-column", {
                attrs: { prop: "appName", label: "投稿公众号" },
              }),
              _vm.activeIndex == "1"
                ? _c("el-table-column", {
                    attrs: { prop: "contributeStatus", label: "状态" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "default",
                          fn: function (scope) {
                            return [
                              scope.row.contributeStatus == "2"
                                ? _c(
                                    "div",
                                    { staticStyle: { color: "#3FA2CC" } },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            scope.row.contributeStatusStr
                                          ) +
                                          " "
                                      ),
                                    ]
                                  )
                                : scope.row.contributeStatus == "3"
                                ? _c(
                                    "div",
                                    { staticStyle: { color: "#D3AF31" } },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            scope.row.contributeStatusStr
                                          ) +
                                          " "
                                      ),
                                    ]
                                  )
                                : scope.row.status == "4"
                                ? _c(
                                    "div",
                                    { staticStyle: { color: "#E55E55" } },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            scope.row.contributeStatusStr
                                          ) +
                                          " "
                                      ),
                                    ]
                                  )
                                : scope.row.status == "6"
                                ? _c(
                                    "div",
                                    { staticStyle: { color: "#3FA2CC" } },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            scope.row.contributeStatusStr
                                          ) +
                                          " "
                                      ),
                                    ]
                                  )
                                : _c("div", [
                                    _vm._v(
                                      " " +
                                        _vm._s(scope.row.contributeStatusStr) +
                                        " "
                                    ),
                                  ]),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1428313162
                    ),
                  })
                : _vm._e(),
              _c("el-table-column", {
                attrs: { prop: "penName", label: "笔名" },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "operate",
                  align: "center",
                  width: "400",
                  label: "操作",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm.activeIndex == "9"
                          ? _c("div", [
                              _c(
                                "span",
                                {
                                  staticClass: "operateItem",
                                  on: {
                                    click: function ($event) {
                                      return _vm.editArticleInfo(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("修改文章信息")]
                              ),
                              _c(
                                "span",
                                {
                                  staticClass: "operateItem",
                                  on: {
                                    click: function ($event) {
                                      return _vm.downFile(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("下载文章")]
                              ),
                              _c(
                                "span",
                                {
                                  staticClass: "operateItem",
                                  on: {
                                    click: function ($event) {
                                      return _vm.viewArticle(
                                        scope.row.publishLink
                                      )
                                    },
                                  },
                                },
                                [_vm._v("查看")]
                              ),
                              _c(
                                "span",
                                {
                                  staticClass: "operateItem",
                                  on: {
                                    click: function ($event) {
                                      return _vm.editArticle(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("编辑")]
                              ),
                              _c(
                                "span",
                                {
                                  staticClass: "operateItem",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleEditFee(scope.row.id)
                                    },
                                  },
                                },
                                [_vm._v("稿酬编辑")]
                              ),
                              _c(
                                "span",
                                {
                                  staticClass: "operateItem",
                                  on: {
                                    click: function ($event) {
                                      return _vm.changeContributeStatus(
                                        scope.row
                                      )
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      scope.row.contributeStatus == "11"
                                        ? "恢复"
                                        : "作废"
                                    )
                                  ),
                                ]
                              ),
                            ])
                          : _vm._e(),
                        _vm.activeIndex == "1" &&
                        scope.row.contributeStatus == "11"
                          ? _c(
                              "div",
                              { staticStyle: { "margin-right": "20px" } },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "operateItem",
                                    on: {
                                      click: function ($event) {
                                        return _vm.editArticleInfo(scope.row)
                                      },
                                    },
                                  },
                                  [_vm._v("修改文章信息")]
                                ),
                                _c(
                                  "span",
                                  {
                                    staticClass: "operateItem",
                                    on: {
                                      click: function ($event) {
                                        return _vm.changeContributeStatus(
                                          scope.row
                                        )
                                      },
                                    },
                                  },
                                  [_vm._v("恢复")]
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm.activeIndex == "1" &&
                        (scope.row.contributeStatus == "1" ||
                          scope.row.contributeStatus == "10")
                          ? _c("div", [
                              _c(
                                "span",
                                {
                                  staticClass: "operateItem",
                                  on: {
                                    click: function ($event) {
                                      return _vm.editArticleInfo(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("修改文章信息")]
                              ),
                              _c(
                                "span",
                                {
                                  staticClass: "operateItem",
                                  on: {
                                    click: function ($event) {
                                      return _vm.downFile(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("下载文章")]
                              ),
                              _c(
                                "span",
                                {
                                  staticClass: "operateItem",
                                  on: {
                                    click: function ($event) {
                                      return _vm.receiveManuscript(scope.row.id)
                                    },
                                  },
                                },
                                [_vm._v("接收")]
                              ),
                              _c(
                                "span",
                                {
                                  staticClass: "operateItem",
                                  on: {
                                    click: function ($event) {
                                      return _vm.editOrRejectManuscript(
                                        scope.row.id,
                                        "edit"
                                      )
                                    },
                                  },
                                },
                                [_vm._v("修回")]
                              ),
                              _c(
                                "span",
                                {
                                  staticClass: "operateItem",
                                  on: {
                                    click: function ($event) {
                                      return _vm.editOrRejectManuscript(
                                        scope.row.id,
                                        "reject"
                                      )
                                    },
                                  },
                                },
                                [_vm._v("拒稿")]
                              ),
                              _c(
                                "span",
                                {
                                  staticClass: "operateItem",
                                  on: {
                                    click: function ($event) {
                                      return _vm.changeContributeStatus(
                                        scope.row
                                      )
                                    },
                                  },
                                },
                                [_vm._v("作废")]
                              ),
                            ])
                          : _vm._e(),
                        _vm.activeIndex == "1" &&
                        scope.row.contributeStatus == "2"
                          ? _c("div", [
                              _c(
                                "span",
                                {
                                  staticClass: "operateItem",
                                  on: {
                                    click: function ($event) {
                                      return _vm.editArticleInfo(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("修改文章信息")]
                              ),
                              _c(
                                "span",
                                {
                                  staticClass: "operateItem",
                                  on: {
                                    click: function ($event) {
                                      return _vm.downFile(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("下载文章")]
                              ),
                              _c(
                                "span",
                                {
                                  staticClass: "operateItem",
                                  on: {
                                    click: function ($event) {
                                      return _vm.changeContributeStatus(
                                        scope.row
                                      )
                                    },
                                  },
                                },
                                [_vm._v("作废")]
                              ),
                            ])
                          : _vm._e(),
                        _vm.activeIndex == "1" &&
                        scope.row.contributeStatus == "3"
                          ? _c("div", [
                              _c(
                                "span",
                                {
                                  staticClass: "operateItem",
                                  on: {
                                    click: function ($event) {
                                      return _vm.editArticleInfo(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("修改文章信息")]
                              ),
                              _c(
                                "span",
                                {
                                  staticClass: "operateItem",
                                  on: {
                                    click: function ($event) {
                                      return _vm.downFile(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("下载文章")]
                              ),
                              _c(
                                "span",
                                {
                                  staticClass: "operateItem",
                                  on: {
                                    click: function ($event) {
                                      return _vm.viewReason(scope.row, "edit")
                                    },
                                  },
                                },
                                [_vm._v("查看修改建议")]
                              ),
                              _c(
                                "span",
                                {
                                  staticClass: "operateItem",
                                  on: {
                                    click: function ($event) {
                                      return _vm.changeContributeStatus(
                                        scope.row
                                      )
                                    },
                                  },
                                },
                                [_vm._v("作废")]
                              ),
                            ])
                          : _vm._e(),
                        _vm.activeIndex == "1" &&
                        scope.row.contributeStatus == "4"
                          ? _c("div", [
                              _c(
                                "span",
                                {
                                  staticClass: "operateItem",
                                  on: {
                                    click: function ($event) {
                                      return _vm.editArticleInfo(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("修改文章信息")]
                              ),
                              _c(
                                "span",
                                {
                                  staticClass: "operateItem",
                                  on: {
                                    click: function ($event) {
                                      return _vm.downFile(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("下载文章")]
                              ),
                              _c(
                                "span",
                                {
                                  staticClass: "operateItem",
                                  on: {
                                    click: function ($event) {
                                      return _vm.viewReason(scope.row, "reject")
                                    },
                                  },
                                },
                                [_vm._v("查看拒稿原因")]
                              ),
                              _c(
                                "span",
                                {
                                  staticClass: "operateItem",
                                  on: {
                                    click: function ($event) {
                                      return _vm.changeContributeStatus(
                                        scope.row
                                      )
                                    },
                                  },
                                },
                                [_vm._v("作废")]
                              ),
                            ])
                          : _vm._e(),
                        _vm.activeIndex == "1" &&
                        scope.row.contributeStatus == "5"
                          ? _c("div", [
                              _c(
                                "span",
                                {
                                  staticClass: "operateItem",
                                  on: {
                                    click: function ($event) {
                                      return _vm.editArticleInfo(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("修改文章信息")]
                              ),
                              _c(
                                "span",
                                {
                                  staticClass: "operateItem",
                                  on: {
                                    click: function ($event) {
                                      return _vm.downFile(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("下载文章")]
                              ),
                              _c(
                                "span",
                                {
                                  staticClass: "operateItem",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleFinishLayout(
                                        scope.row.id
                                      )
                                    },
                                  },
                                },
                                [_vm._v("排版完成")]
                              ),
                              _c(
                                "span",
                                {
                                  staticClass: "operateItem",
                                  on: {
                                    click: function ($event) {
                                      return _vm.changeContributeStatus(
                                        scope.row
                                      )
                                    },
                                  },
                                },
                                [_vm._v("作废")]
                              ),
                            ])
                          : _vm._e(),
                        _vm.activeIndex == "1" &&
                        scope.row.contributeStatus == "6"
                          ? _c("div", [
                              _c(
                                "span",
                                {
                                  staticClass: "operateItem",
                                  on: {
                                    click: function ($event) {
                                      return _vm.editArticleInfo(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("修改文章信息")]
                              ),
                              _c(
                                "span",
                                {
                                  staticClass: "operateItem",
                                  on: {
                                    click: function ($event) {
                                      return _vm.downFile(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("下载文章")]
                              ),
                              _c(
                                "span",
                                {
                                  staticClass: "operateItem",
                                  on: {
                                    click: function ($event) {
                                      return _vm.editPreviewLink(
                                        scope.row.id,
                                        scope.row.previewLink
                                      )
                                    },
                                  },
                                },
                                [_vm._v("修改预览链接")]
                              ),
                              _c(
                                "span",
                                {
                                  staticClass: "operateItem",
                                  on: {
                                    click: function ($event) {
                                      return _vm.changeContributeStatus(
                                        scope.row
                                      )
                                    },
                                  },
                                },
                                [_vm._v("作废")]
                              ),
                            ])
                          : _vm._e(),
                        _vm.activeIndex == "1" &&
                        scope.row.contributeStatus == "7"
                          ? _c("div", [
                              _c(
                                "span",
                                {
                                  staticClass: "operateItem",
                                  on: {
                                    click: function ($event) {
                                      return _vm.editArticleInfo(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("修改文章信息")]
                              ),
                              _c(
                                "span",
                                {
                                  staticClass: "operateItem",
                                  on: {
                                    click: function ($event) {
                                      return _vm.downFile(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("下载文章")]
                              ),
                              _c(
                                "span",
                                {
                                  staticClass: "operateItem",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleScheduleDate(
                                        scope.row.id,
                                        scope.row.prePublishDate
                                      )
                                    },
                                  },
                                },
                                [_vm._v("排期")]
                              ),
                              _c(
                                "span",
                                {
                                  staticClass: "operateItem",
                                  on: {
                                    click: function ($event) {
                                      return _vm.changeContributeStatus(
                                        scope.row
                                      )
                                    },
                                  },
                                },
                                [_vm._v("作废")]
                              ),
                            ])
                          : _vm._e(),
                        _vm.activeIndex == "1" &&
                        scope.row.contributeStatus == "8"
                          ? _c("div", [
                              _c(
                                "span",
                                {
                                  staticClass: "operateItem",
                                  on: {
                                    click: function ($event) {
                                      return _vm.editArticleInfo(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("修改文章信息")]
                              ),
                              _c(
                                "span",
                                {
                                  staticClass: "operateItem",
                                  on: {
                                    click: function ($event) {
                                      return _vm.downFile(scope.row)
                                    },
                                  },
                                },
                                [_vm._v("下载文章")]
                              ),
                              _c(
                                "span",
                                {
                                  staticClass: "operateItem",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleConfirmPublish(
                                        scope.row.id
                                      )
                                    },
                                  },
                                },
                                [_vm._v("确认发布")]
                              ),
                              _c(
                                "span",
                                {
                                  staticClass: "operateItem",
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleScheduleDate(
                                        scope.row.id,
                                        scope.row.prePublishDate
                                      )
                                    },
                                  },
                                },
                                [_vm._v("修改排期")]
                              ),
                              _c(
                                "span",
                                {
                                  staticClass: "operateItem",
                                  on: {
                                    click: function ($event) {
                                      return _vm.changeContributeStatus(
                                        scope.row
                                      )
                                    },
                                  },
                                },
                                [_vm._v("作废")]
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "pagination" },
        [
          _c("Pagination", {
            attrs: {
              total: _vm.total,
              limit: _vm.pageSize,
              currentPage: _vm.currentPage,
            },
            on: { pagination: _vm.pagination },
          }),
        ],
        1
      ),
      _vm.downFileFlag ||
      _vm.editArticleInfoFlag ||
      _vm.editArticleFlag ||
      _vm.editFeeFlag ||
      _vm.newScriptFlag ||
      _vm.rejectOrEditManuscriptFlag ||
      _vm.receiveManuscriptFlag ||
      _vm.finishLayoutFlag ||
      _vm.scheduleDateFlag ||
      _vm.publishFlag
        ? _c("userOverlayout")
        : _vm._e(),
      _c("rejectOrEditManuscript", {
        attrs: {
          id: _vm.rejectOrEditManuscriptId,
          rejectOrEditManuscriptFlag: _vm.rejectOrEditManuscriptFlag,
          contributeStatus: _vm.contributeStatus,
        },
        on: {
          rejectOrEditManuscriptcomfirn: _vm.rejectOrEditManuscriptcomfirn,
          rejectOrEditManuscriptClose: function ($event) {
            _vm.rejectOrEditManuscriptFlag = false
          },
        },
      }),
      _c("receiveManuscript", {
        attrs: {
          type: "2",
          receiveManuscriptFlag: _vm.receiveManuscriptFlag,
          id: _vm.receiveManuscriptId,
        },
        on: {
          receiveManuscriptcomfirn: _vm.receiveManuscriptcomfirn,
          receiveManuscriptClose: function ($event) {
            _vm.receiveManuscriptFlag = false
          },
        },
      }),
      _c("finishLayout", {
        attrs: {
          linkType: _vm.linkType,
          previewLink: _vm.previewLink,
          finishLayoutFlag: _vm.finishLayoutFlag,
          id: _vm.finishLayoutId,
        },
        on: {
          finishLayoutconfirm: _vm.finishLayoutconfirm,
          closeFinishLayout: function ($event) {
            _vm.finishLayoutFlag = false
          },
        },
      }),
      _c("scheduleDate", {
        attrs: {
          prePublishDate: _vm.prePublishDate,
          scheduleDateFlag: _vm.scheduleDateFlag,
          id: _vm.scheduleDateId,
        },
        on: {
          scheduleDateconfirm: _vm.scheduleDateconfirm,
          closeScheduleDate: function ($event) {
            _vm.scheduleDateFlag = false
          },
        },
      }),
      _c("publish", {
        attrs: { publishFlag: _vm.publishFlag, id: _vm.publishId },
        on: {
          publishConfirm: _vm.publishConfirm,
          closePublish: function ($event) {
            _vm.publishFlag = false
          },
        },
      }),
      _c("newScriptComponent", {
        attrs: { newScriptFlag: _vm.newScriptFlag },
        on: {
          comfirnNewScript: _vm.comfirnNewScript,
          closeNewScript: function ($event) {
            _vm.newScriptFlag = false
          },
        },
      }),
      _c("editFee", {
        attrs: { editFeeFlag: _vm.editFeeFlag, editFeeId: _vm.editFeeId },
        on: {
          editFeeConfirm: _vm.editFeeConfirm,
          closeEditFeeFlag: function ($event) {
            _vm.editFeeFlag = false
          },
        },
      }),
      _c("editArticle", {
        attrs: { editArticleFlag: _vm.editArticleFlag, id: _vm.editArticleId },
        on: {
          editArticleConfirm: _vm.editArticleConfirm,
          closeEditArticle: function ($event) {
            _vm.editArticleFlag = false
          },
        },
      }),
      _c("editArticleInfo", {
        attrs: {
          editArticleInfoObj: _vm.editArticleInfoObj,
          editArticleInfoFlag: _vm.editArticleInfoFlag,
        },
        on: {
          confirmEditArticleInfo: _vm.confirmEditArticleInfo,
          closeEditArticleInfo: function ($event) {
            _vm.editArticleInfoFlag = false
          },
        },
      }),
      _c("userDownFile", {
        attrs: {
          contributeStatus: _vm.contributeStatus,
          contributeUrl: _vm.contributeUrl,
          contributeFileName: _vm.contributeFileName,
          downFileFlag: _vm.downFileFlag,
        },
        on: {
          downFileClose: function ($event) {
            _vm.downFileFlag = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }