var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.editFeeFlag
    ? _c("div", { staticClass: "editFee" }, [
        _c("div", { staticClass: "header" }, [
          _c("div", { staticClass: "title" }, [_vm._v("编辑稿酬")]),
          _c("img", {
            staticStyle: { width: "15px", height: "15px", cursor: "pointer" },
            attrs: { src: require("../../assets/images/close.png") },
            on: { click: _vm.close },
          }),
        ]),
        _c("div", { staticClass: "content" }, [
          _c(
            "div",
            {
              staticStyle: {
                display: "flex",
                "align-items": "center",
                "margin-bottom": "20px",
              },
            },
            [
              _c("div", { staticStyle: { width: "100px" } }, [
                _vm._v("系统计算："),
              ]),
              _c("div", [_vm._v(_vm._s(_vm.sysTotalFee))]),
            ]
          ),
          _c(
            "div",
            [
              _c(
                "el-form",
                {
                  ref: "ruleForm",
                  staticClass: "demo-ruleForm",
                  attrs: {
                    model: _vm.ruleForm,
                    rules: _vm.rules,
                    "label-position": "left",
                    "label-width": "100px",
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "自定义：", prop: "customFee" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入自定义稿酬" },
                        model: {
                          value: _vm.ruleForm.customFee,
                          callback: function ($$v) {
                            _vm.$set(_vm.ruleForm, "customFee", $$v)
                          },
                          expression: "ruleForm.customFee",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "footer" }, [
          _c("div", { staticClass: "comfirn", on: { click: _vm.comfirn } }, [
            _vm._v("确认"),
          ]),
          _c(
            "div",
            {
              staticClass: "comfirn",
              staticStyle: { "margin-left": "20px" },
              on: { click: _vm.close },
            },
            [_vm._v("关闭")]
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }