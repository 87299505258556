var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.downFileFlag
    ? _c("div", { staticClass: "downFile" }, [
        _c("div", { staticClass: "header" }, [
          _c("div", { staticClass: "title" }, [_vm._v("下载修回文件")]),
          _c("img", {
            staticStyle: { width: "15px", height: "15px", cursor: "pointer" },
            attrs: { src: require("../../assets/images/close.png") },
            on: { click: _vm.close },
          }),
        ]),
        _c(
          "div",
          { staticClass: "footer" },
          [
            _c(
              "el-button",
              { attrs: { type: "primary" }, on: { click: _vm.downFile } },
              [_vm._v("下载")]
            ),
            _c("el-button", { on: { click: _vm.close } }, [_vm._v("关闭")]),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }