<template>
  <div v-if="newScriptFlag" class="newScript">
		<div class="header">
			<div class="title">新增投稿</div>
			<img @click="close" style="width: 15px;height:15px;cursor: pointer;" src="../../assets/images/close.png" />
		</div>
		<div class="content">
			<el-form style="width:100%" :model="ruleForm" :rules="rules" label-position="left" label-width="100px" ref="ruleForm" class="demo-ruleForm">
				<el-form-item label="标题" prop="title">
					<el-input size="small" maxlength="30" v-model="ruleForm.title"></el-input>
				</el-form-item>
				<el-form-item label="文章类型" prop="specialStatus">
					<el-checkbox @change="changeStatus" :disabled="specialStatusDisabled" v-model="ruleForm.specialStatus">特约投稿</el-checkbox>
				</el-form-item>
				<el-form-item label="投稿类型:" prop="scriptType">
					<el-select :disabled="scriptTypeDisabled" v-model="ruleForm.scriptType" placeholder="请选择">
						<el-option
							v-for="item in scriptTypeList"
							:key="item.value"
							:label="item.label"
							:value="item.value">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="文章分类" prop="category">
					<el-select v-model="ruleForm.category"
						placeholder="请选择文章分类"
						size="small"
						class="statusClass"
					>
						<el-option
							v-for="item in categoryList"
							:key="item.value"
							:label="item.label"
							:value="item.value"
						/>
					</el-select>
				</el-form-item>
				<el-form-item label="公众号:" prop="id">
					<el-select v-model="ruleForm.id"
						placeholder="请选择公众号"
						size="small"
						@change="changeApp"
						class="statusClass"
					>
						<el-option
							v-for="item in appList"
							:key="item.id"
							:label="item.appName"
							:value="item.id"
							:disabled="item.disabled"
						/>
					</el-select>
				</el-form-item>
				<el-form-item label="微信用户:" prop="wxNumber">
					<el-select
						v-model="ruleForm.wxNumber"
						filterable
						remote
						reserve-keyword
						placeholder="请输入关键词"
						:remote-method="remoteMethod"
						:loading="loading">
						<el-option
							v-for="item in wxNameList"
							:key="item.id"
							:label="item.userName"
							:value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="投稿日期:" prop="contributeDate">
					<el-date-picker
					v-model="ruleForm.contributeDate"
					type="date"
					size="small"
					value-format="yyyy-MM-dd"
					placeholder="选择日期">
				</el-date-picker>
				</el-form-item>
				<el-form-item label="" prop="">
					<el-upload
						class="upload-demo"
						:headers="headers"
						:before-upload="beforeUpload"
						:action="baseURL+'/system/file/uploadFile'"
						:data="uploadParams"
						:limit="1"
						:on-success="(res, file, fileList)=>{uploadSuccess(res, file, fileList)}"
						:file-list="fileList">
						<el-button size="small" type="primary">上传投稿文档</el-button>
					</el-upload>
				</el-form-item>
			</el-form>
		</div>
		<div class="footer">
			<div class="comfirn" @click="comfirn">确认</div>
			<div class="comfirn" style="margin-left: 20px" @click="close">关闭</div>
		</div>
  </div>
</template>
<script>
import {getSetupConfig} from '../../api/userHome.js'
import {personInfoList} from '../../api/personInfo.js'
import {newManuscript} from '../../api/home.js'
import { getToken } from '@/utils/auth'
export default {
	props: ['newScriptFlag'],
  data() {
    return {
			specialStatusDisabled: false,
			scriptTypeDisabled: false,
			headers: {
        Authorization: getToken()
      },
			baseURL: process.env.VUE_APP_BASE_API,
			// baseURL:'http://172.16.0.109:8070',
			//上传文件额外参数
      uploadParams: {
        fileClassify: 12,
        tempFlag: 1
      },
			loading: false,
			scriptTypeList: [{
				label: '自行投稿',
				value: '1'
			},{
				label: '约稿',
				value: '2'
			}],
			categoryList: [{
				label: '面向药师',
				value: '1'
			},{
				label: '大众科普',
				value: '2'
			},{
				label: '不确定',
				value: '3'
			}],
			appList: [],
			wxNameList: [],
			fileList: [],
			ruleForm: {
				title: '',
				scriptType: '',
				id: '',
				wxNumber: '',
				contributeDate: '',
				contributeFileName: '',
				contributeUrl: '',
				category: '',
				specialStatus: false
			},
			configId: '',
			appId: '',
			rules: {
				title: [
            {required: true, message: '请输入标题', trigger: 'blur' },
        ],
				wxNumber: [
            {required: true, message: '请选择微信用户', trigger: 'change' },
        ],
				category: [
            { required: true, message: '请选择文章分类', trigger: 'change' },
        ],
        id: [
            { required: true, message: '请选择公众号', trigger: 'change' },
        ],
				contributeDate: [
            { required: true, message: '请选择投稿日期', trigger: 'change' },
        ],
      }
    }
  },
	watch: {
		newScriptFlag(newVal) {
			if(newVal) {
				this.getSetupConfigMethod()
			}
		}
	},
  created() {
  },
  methods: {
		changeStatus(val) {
			if(val) {
				this.scriptTypeDisabled = true
				this.ruleForm.scriptType = '2'
				this.appList.forEach( item =>{
					if(item.id == 3) {
						item.disabled = true
					}
				})
			} else {
				this.scriptTypeDisabled = false
				this.appList.forEach( item =>{
					item.disabled = false
				})
			}
		},
		changeApp(val) {
			this.appList.forEach( item =>{
				if(item.id == val) {
					this.appId = item.appId
				}
			})
			for(let i=0; i<this.appList.length;i++) {
				if(val == 3) {
					this.specialStatusDisabled = true
					this.ruleForm.specialStatus = false
					break
				} else {
					this.specialStatusDisabled = false
				}
			}
		},
		uploadSuccess(response,file,fileList) {
			if(response.code == 200) {
				this.ruleForm.contributeFileName = fileList[0].name
				this.ruleForm.contributeUrl = fileList[0].response.data.id
			} else {
				this.fileList = []
				this.$message.error(response.msg || '上传失败')
			}
    },
		async remoteMethod(query) {
			if (query == '') {
				return
			}
			this.loading = true
			const params = {
				pageNum: 1,
				pageSize: 50,
				searchValue: query
			}
			let res = await personInfoList(params)
			this.loading = false
			if(res.code == 200) {
				this.wxNameList = res.rows
			}
		},
		getSetupConfigMethod(){
      getSetupConfig().then(res=>{
        if(res.code == 200) {
				this.appList = res.data
				this.appList.forEach( item =>{
					item.disabled = false
				})
			}
      })
    },
		comfirn() {
			this.$refs.ruleForm.validate( async (valid) => {
				if (valid) {
					// if(!this.ruleForm.contributeUrl) {
					// 	this.$message.error('请上传文档')
					// 	return
					// }
					const params = {
						configId: this.ruleForm.id,//"wx0ca1177c7ba1b74c",
						contributeFileName: this.ruleForm.contributeFileName,
						contributeUrl: this.ruleForm.contributeUrl,
						userId: this.ruleForm.wxNumber,
						penName: '',
						title: this.ruleForm.title,
						usePen: 0,
						contributeDate: this.ruleForm.contributeDate,
						type: this.ruleForm.scriptType,
						category: parseInt(this.ruleForm.category),
						specialStatus: this.ruleForm.specialStatus ? 1 : 0,
						appId: this.appId
					}
					let res = await newManuscript(params)
					if(res.code == 200) {
						this.fileList = []
						this.wxNameList = []
						this.$refs.ruleForm.resetFields()
						this.$emit('comfirnNewScript')
					}
				} else {
					return false
				}
			})
		},
		// 文件上传前校验
    beforeUpload(file) {
      let fileName = file.name
      let lastIndex = fileName.lastIndexOf('.')
      let type = fileName.substring(lastIndex+1) // doc,docx,xls,xlsx,ppt,pptx,txt,pdf
      // if (this.form.type===2) {
      if (type !== 'doc' && type !== 'docx') {
        this.$message.error('暂不支持此文件格式，请检查后重新上传')
        return false;
      }
      // }
    },
		close() {
			this.wxNameList = []
			this.fileList = []
			this.$refs.ruleForm.resetFields()
			this.$emit('closeNewScript')
		}
  },
}
</script>
<style lang="scss" scoped>
::v-deep .el-upload-list__item-name {
	white-space: pre-wrap;
}
::v-deep .el-form-item {
	width: 100%;
}
::v-deep .el-select {
	width: 100%;
}
::v-deep .el-date-editor {
	width: 100%;
}
.newScript {
	//height: 320px;
	width: 500px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	border-radius: 8px 8px 0px 0px;
	border-radius: 8px 8px 0px 0px;
	background: #FFF;
	display: flex;
	flex-direction: column;
	z-index: 1000;
	.footer {
		display: flex;
		padding: 20px 20px 10px 20px;
		justify-content: center;
		align-items: center;
		flex: 0 0 60px;
		box-sizing: border-box;
		.comfirn {
			//width: 68px;
			cursor: pointer;
			height: 30px;
			border-radius: 4px;
			border: 1px solid #B9B9B9;
			background: #FFF;
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 8px 20px;
			box-sizing: border-box;
			line-height: 14px;
			font-size: 14px;
			color: #747474;
		}
	}
	.content {
		padding: 10px 20px;
		flex: 1;
		display: flex;
	}
	.header {
		display: flex;
		padding: 20px 20px 10px 20px;
		justify-content: space-between;
		align-items: center;
		flex: 0 0 60px;
		box-sizing: border-box;
		.title {
			color: #262626;
			font-size: 20px;
			font-weight: bold;
			line-height: 30px;
		}
	}
}
</style>
