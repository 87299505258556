<template>
  <div v-if="downFileFlag" class="downFile">
		<div class="header">
			<div class="title">下载修回文件</div>
			<img @click="close" style="width: 15px;height:15px;cursor: pointer;" src="../../assets/images/close.png" />
		</div>
		<div class="footer">
			<el-button type="primary" @click="downFile">下载</el-button>
      <el-button @click="close">关闭</el-button>
		</div>
  </div>
</template>
<script>
import {downloadFile, getDownFileType} from '../../api/home.js'
import {axiosDownload} from '@/utils/common'
export default {
	props: ['downFileFlag', 'contributeUrl', 'contributeFileName', 'contributeStatus'], //type: 1用户 2平台
  data() {
    return {
      //contributeUrl: ''
    }
  },
  created() {

  },
  methods: {
    async downFile() {
      let res = await downloadFile(parseInt(this.contributeUrl));
			let type = await getDownFileType(parseInt(this.contributeUrl))
      axiosDownload(`${type.data.fileName}`, res)
      this.$emit('downFileClose')
    },
		close() {
			this.$emit('downFileClose')
		}
  },
}
</script>
<style lang="scss" scoped>
.downFile {
	//height: 320px;
	width: 500px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	border-radius: 8px 8px 0px 0px;
	border-radius: 8px 8px 0px 0px;
	background: #FFF;
	display: flex;
	flex-direction: column;
	z-index: 1000;
	.footer {
		display: flex;
		padding: 20px 20px 10px 20px;
		justify-content: center;
		align-items: center;
		flex: 0 0 60px;
		box-sizing: border-box;
		.comfirn {
			//width: 68px;
			cursor: pointer;
			height: 30px;
			border-radius: 4px;
			border: 1px solid #B9B9B9;
			background: #FFF;
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 8px 20px;
			box-sizing: border-box;
			line-height: 14px;
			font-size: 14px;
			color: #747474;
		}
	}
	.content {
		padding: 10px 20px;
		flex: 1;
		display: flex;
    flex-direction: column;
    .tip {
      border: 1px dashed #ffba00;
      background-color: #f3da97;
    }
	}
	.header {
		display: flex;
		padding: 20px 20px 10px 20px;
		justify-content: space-between;
		align-items: center;
		flex: 0 0 60px;
		box-sizing: border-box;
		.title {
			color: #262626;
			font-size: 20px;
			font-weight: bold;
			line-height: 30px;
		}
	}
}
</style>
