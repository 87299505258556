import request from '@/utils/request'
//个人信息查询 如果平台端根据id 号查看 如果是用户端 根据登录用户查看,用户端的时候不用传 id
export function getPersonInfo(params) {
	return request({
		url: `/thys-api/contribute/user/userinfo`,
		method: 'get',
		header: {
      'content-type': 'application/x-www-form-urlencoded',
    },
		params: params
	})
}
//个人信息更新-会返回审核状态
export function updatePersonInfo(params) {
	return request({
		url: `/thys-api/contribute/user/userinfo`,
		method: 'put',
		data: params
	})
}
//用户信息列表
export function personInfoList(params) {
	return request({
		url: `/thys-api/contribute/user/page/list`,
		method: 'post',
		data: params
	})
}
//更新密码,只有平台端登录能更新
export function updatePwd(params) {
	return request({
		url: `/thys-api/contribute/user/resetPwd`,
		method: 'put',
		header: {
      'content-type': 'application/x-www-form-urlencoded',
    },
		params: params
	})
}
//平台端-审核状态更新
export function updateStatus(params) {
	return request({
		url: `/thys-api/contribute/user/user/status`,
		method: 'put',
		data: params
	})
}