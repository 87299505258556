<template>
  <div v-if="publishFlag" class="publish">
    <div class="header">
      <div class="title">确认发布</div>
      <img @click="close" style="width: 15px;height:15px;cursor: pointer;" src="../../assets/images/close.png" />
    </div>
    <div class="content">
      <div style="margin-bottom: 10px">日期：<el-date-picker v-model="date" type="date" size="small"
          value-format="yyyy-MM-dd" placeholder="选择日期">
        </el-date-picker></div>
      <el-input style="flex: 1" type="textarea" placeholder="在此处粘贴文章链接" v-model="publishLink">
      </el-input>
      <el-checkbox style="margin: 10px 0" v-model="originalRewardFlag">原创奖励</el-checkbox>
      <!-- <el-input
				style="flex: 1"
				type="textarea"
				placeholder="在此处粘贴封面图链接"
				v-model="thumbLink">
			</el-input> -->

      <!-- <img v-if="thumbLink" style="width: 212px;height:90px;margin-top:10px" :src="thumbLink" /> -->

      <!-- <el-form-item label="文件："> -->
      <el-row style="margin-bottom: 10px">
        <el-col :span="5">
          <div style="line-height: 36px;">封面地址：</div>
        </el-col>
        <el-col :span="13">
          <el-input v-model="form.file" clearable @clear="clearClick"></el-input>
        </el-col>
        <el-col :span="6" style="text-align: right;">
          <el-upload ref="upload" class="upload-demo" :action="baseURL + '/system/file/uploadFile'" :data="uploadParams"
            :on-preview="handleFilePreview" :before-upload="beforeUpload"
            :on-success="(res, file, fileList) => { uploadSuccess(res, file, fileList) }" :on-remove="handleRemove"
            :before-remove="beforeRemove" :show-file-list="false" multiple :limit="1" :on-exceed="handleExceed"
            :file-list="fileList">
            <el-button slot="trigger" style="border:1px solid #2D5AFA;color: #2D5AFA;" size="small"
              :loading="uploadLoading" v-if="fileList.length === 0">点击上传</el-button>
            <el-button slot="tip" style="border:1px solid #2D5AFA;color: #2D5AFA;" size="small" :loading="uploadLoading"
              v-else :disabled="true">点击上传</el-button>
          </el-upload>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="24" v-if="previewPhotoUrl">
          <!-- style="height:397px; width:486px" -->
          <img :src="previewPhotoUrl" alt="photo" style="width: 100%;">
          <!-- onload="var max=400;if(Math.max(this.width,this.height)>max){if(this.width>this.height)this.width=max;else this.height=max;}" -->
        </el-col>
      </el-row>

      <!-- </el-form-item> -->
    </div>
    <div class="footer">
      <div class="comfirn" @click="comfirn">确认</div>
    </div>
  </div>
</template>
<script>
import { publish } from '../../api/userHome.js'
import {axiosDownload} from '@/utils/common'
import {downloadFile,delFile} from '@/api/system/fileUpload'
export default {
	props: ['publishFlag', 'id'],
  data() {
    return {
      publishLink: '',
      originalRewardFlag: false,
      thumbLink: '',
      date: '',
      form: {
        tebName: [],
        name: '',
        region: '',
        delivery: false,
        type: [],
        file: '',
        resource: 1,
        desc: '',
        path: ''
      },
      fileList: [],
      //上传文件额外参数
      uploadParams: {
        fileClassify: 12, // 富文本上传地址
        tempFlag: 1
      },
      uploadLoading: false,
      baseURL: process.env.VUE_APP_BASE_API,
      uploadData: [],
      previewPhotoUrl:''
    }
  },
  created() {

  },
  methods: {
    async comfirn() {
      if(!this.date){
        this.$message({
          message: '请选择日期',
          type: 'error'
        });
        return
      }
      if (!this.publishLink) {
        this.$message({
          message: '请输入文章链接',
          type: 'error'
        });
        return
      }
      if (!this.form.path) {
        this.$message({
          message: '请输入封面链接',
          type: 'error'
        });
        return
      }

      const params = {
        id: parseInt(this.id),
        originalRewardFlag: this.originalRewardFlag ? 1 : 0,
        publishLink: this.publishLink,
        // thumbLink: this.thumbLink,
        thumbLink: this.form.path,
        publishDate: this.date
      }
      let res = await publish(params)
      if (res.code == 200) {
        this.$message({
          message: '发布完成',
          type: 'success'
        });
        this.publishLink = ''
        this.thumbLink = ''
        this.originalRewardFlag = false
        this.$emit('publishConfirm')
        this.updateTempFlagFn();
        this.close();
      }
    },
    close() {
      this.publishLink = ''
      this.thumbLink = ''
      this.originalRewardFlag = false
      this.$emit('closePublish')
      this.fileList= []
      this.uploadData=[],
      this.form.path='',
      this.previewPhotoUrl=''
      this.publishDate=''
      this.date=''
      this.form.file=''
    },
    // 文件上传前校验
    beforeUpload(file) {
      console.log(file)
      let fileName = file.name
      let lastIndex = fileName.lastIndexOf('.')
      let type = fileName.substring(lastIndex + 1) // png
      console.log(type)
      this.uploadLoading = true
      if (type !== 'png' && type !== 'jpg' && type !== 'jpeg') {
        this.$message.error('上传图片格式不正确，图片格式仅支持格式：jpg、jpeg、png')
        this.uploadLoading = false
        return false;
      } else {
        if (file.size / 1024 / 1024 > 10) {
          this.$message.error('上传文件大小不能超过 10MB');
          this.uploadLoading = false
          return false;
        }
      }
    },
    beforeRemove(file, fileList) {
      if (file && file.status === 'success') {
        // 移除方法
        return this.$confirm(`确定移除 ${file.name}？`);
      }
    },
    // 更新临时文件标志
    async updateTempFlagFn() {
      let obj = {}
      obj.ids = this.uploadData[0].id
      obj.tempFlag = 0
      let res = await updateTempFlag(obj)
      console.log(res)
      if (res.code === 200) {
      }
    },
    async delFileFn() {
      let obj = {}
      obj.id = this.form.path;
      let res = await delFile(this.form.path)
      console.log(res)
      if (res.code === 200) {
        // this.$message.success(res.msg)
        console.log("清除上传的文件")
        this.uploadData = [] // 上传文件清除
        this.fileList = []
        this.previewPhotoUrl =''
      }
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    // 文件上传结束的回调
    uploadSuccess(res, file, fileList) {

      console.log(res, file, fileList)
      if (res.code == 200) {
        this.msgSuccess("上传成功")
        this.uploadLoading = false
        console.log(res)
        // console.log(item)
        this.uploadData.push(res.data)
        // console.log(this.uploadData)
        this.form.file = this.uploadData[0].fileName
        this.form.path = res.data.id
        this.fileList = fileList
        // this.previewPhotoUrl = URL.createObjectURL(res)
        downloadFile(this.form.path).then(res => {
          this.loading = false
          console.log(res)
          this.previewPhotoUrl = URL.createObjectURL(res)
        })
        console.log("图片地址为:"+this.previewPhotoUrl);
      } else {
        // 清空上传文件列表
        this.$refs.upload.clearFiles()
        this.uploadLoading = false
        this.msgError(res.msg || '上传失败')
      }
    },
    handleExceed(files, fileList) {
      console.log(files)
      console.log("****************")
      console.log(fileList)
      console.log(this.fileList)
      this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
    },
    // 处理文件预览预览
    handleFilePreview(file) {
      downloadFile(file.fileId).then(res => {
        axiosDownload(file.name, res)
      })

    },
    clearClick() {
      this.delFileFn()
    },

  },
}
</script>
<style lang="scss" scoped>
::v-deep .el-textarea__inner {
	height: 100%
}
.publish {
	//height: 320px;
	width: 500px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	border-radius: 8px 8px 0px 0px;
	border-radius: 8px 8px 0px 0px;
	background: #FFF;
	display: flex;
	flex-direction: column;
	z-index: 1000;
	.footer {
		display: flex;
		padding: 10px 20px 10px 20px;
		justify-content: center;
		align-items: center;
		flex: 0 0 60px;
		box-sizing: border-box;
		.comfirn {
			//width: 68px;
			cursor: pointer;
			height: 30px;
			border-radius: 4px;
			border: 1px solid #B9B9B9;
			background: #FFF;
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 8px 20px;
			box-sizing: border-box;
			line-height: 14px;
			font-size: 14px;
			color: #747474;
		}
	}
	.content {
		padding: 10px 20px;
		flex: 1;
		display: flex;
		flex-direction: column;
	}
	.header {
		display: flex;
		padding: 20px 20px 10px 20px;
		justify-content: space-between;
		align-items: center;
		flex: 0 0 60px;
		box-sizing: border-box;
		.title {
			color: #262626;
			font-size: 20px;
			font-weight: bold;
			line-height: 30px;
		}
	}
}
</style>
